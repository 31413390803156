
// Большие девайсы (большие десктопы, < 1200px)
@media (max-width: 1199.98px) {

  .header_phone_wrap a {
    font-size: 28px;
  }

  .about h2 {
    margin-top: 20px;
    padding-right: 60px;
    font-size: 42px;
  }

  .about .about_worker p {
    font-size: 16px;
  }

  .how_we_work ul {
    flex-direction: column;
    align-items: center;
  }

  .how_we_work ul li {
    margin-right: 0;
    margin-bottom: 100px;
  }

  .how_we_work ul li:last-of-type {
    margin-bottom: 0;
  }

  .how_we_work ul li:after {
    top: 225px;
    right: 27px;
    transform: rotate(90deg);
  }

  .qualification img {
    display: none;
  }

  .qualification ul {
    margin-right: 100px;
  }

  footer {
    text-align: center;
  }

  footer .left_col {
    flex-direction: column;
    margin-bottom: 20px;
  }

  footer .header_phone_wrap {
    text-align: center;
  }
}

// Средние девайсы («таблетки», < 992px)
@media (max-width: 991.98px) {

  .header_phone_wrap a {
    font-size: 20px;
  }

  footer .header_phone_wrap a {
    font-size: 20px;
  }

  .header_phone_wrap time {
    font-size: 18px;
  }

  .header_phone_wrap a img {
    max-width: 16px;
    margin-right: 7px;
  }

  header_phone_wrap {
    font-size: 15px;
  }

  header .col_right {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .hamburger {
    outline: none !important;
    border: none !important;
  }

  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: #fff;
  }

  header nav ul {
    padding-top: 30px;
    flex-direction: column;

    li {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  .slogan {
    padding-top: 95px;
  }

  header .col_left {
    margin-bottom: 10px;
    text-align: center;
  }

  header .main_logo img {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .slogan h2 {
    font-size: 20px;
    line-height: 22px;
  }

  .about h2 {
    font-size: 28px;
    line-height: 34px;
  }

  .about .about_worker p {
    text-align: center;
    line-height: 20px;
  }

  .social ul li a i {
    font-size: 50px;
  }

  .radiator_slider {
    margin-bottom: 40px;
  }

}

// Малые девайсы («ландшафтные», < 768px)
@media (max-width: 767.98px) {

  .slogan {
    padding-top: 150px;
  }

  .radiators_wrap .radiator_desc ul.main_characteristics {
    margin-top: 40px;
  }

}

// Экстрамалые девайсы («телефоны», < 576px)
@media (max-width: 575.98px) {

  header {
    text-align: center;
  }

  header .col_right {
    margin-top: 10px;
    text-align: center;
  }

  .main_logo img {
    max-width: 180px;
  }

  .header_phone_wrap {
    font-size: 18px;
  }

  .header_phone_wrap time {
    font-size: 14px;
  }

  .header_phone_wrap a img {
    max-width: 13px;
  }

  header .col_left {
    margin-bottom: 0;
  }

  .slogan h2 {
    font-size: 14px;
    line-height: 18px;
  }

  .about {
    height: 387px;
  }

  .about img {
    max-width: 200px;
  }

  .about .about_worker {
    left: 54px;
    bottom: auto;
  }

  .about h2 {
    padding-right: 0;
    font-size: 22px;
    line-height: 26px;
  }

  .order_form h3 {
    margin-bottom: 24px;
    font-size: 22px;
  }

  .order_form button {
    font-size: 16px;
    line-height: 50px;
  }

  .why_we h2 {
    font-size: 28px;
  }

  .why_we h2:before {
    width: 230px;
    margin-left: -115px;
  }

  .why_we .why_item img {
    float: none;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .why_we .why_desc {
    padding-right: 0;
  }

  .why_we .why_item {
    flex-direction: column;
    text-align: center;
  }

  .why_we .why_desc p {
    font-size: 16px;
    line-height: 24px;
  }

  .how_we_work h2 {
    font-size: 28px;
  }

  .how_we_work h2:before {
    width: 300px;
    margin-left: -150px;
  }

  .how_we_work h3 {
    font-size: 22px;
  }

  .how_we_work button {
    font-size: 16px;
    line-height: 50px;
  }

  .services h2 {
    font-size: 28px;
  }

  .services h2:before {
    width: 300px;
    margin-left: -150px;
  }

  .services .service_item h4 {
    font-size: 20px;
  }

  .qualification ul {
    margin-left: auto;
    margin-right: auto;
  }

  .qualification h3 {
    padding-left: 0;
    text-align: center;
  }

  .qualification ul li {
    font-size: 14px;
    line-height: 18px;
  }

  .reviews h2 {
    font-size: 28px;
  }

  .reviews h2:before {
    width: 300px;
    margin-left: -150px;
  }

  .social h3 {
    font-size: 18px;
  }

  .social ul li a i {
    font-size: 34px;
  }

  footer .left_col h3 {
    font-size: 22px;
  }

  .directory_selection h2 {
    font-size: 28px;
  }

  .radiators_wrap h2 {
    font-size: 28px;
  }

  .radiators_wrap h2:before {
    width: 300px;
    margin-left: -150px;
  }

  .directory_selection .catalog_item h3 {
    font-size: 26px;
  }

  .radiators_wrap .radiator_row h3 {
    font-size: 26px;
  }

  .radiators_wrap .radiator_desc h5 {
    font-size: 24px;
  }

  .directory_selection a {
    font-size: 16px;
    line-height: 50px;
  }

  .radiators_wrap .radiator_desc .price {
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
    font-size: 34px;
  }

  .radiators_wrap .radiator_desc a {
    max-width: 300px;
  }

  .radiators_wrap .radiator_desc ul.main_characteristics li {
    font-size: 16px;
    line-height: 18px;
  }

  .radiators_wrap .radiator_desc ul.additional_characteristics li h6 {
    font-size: 16px;
  }

  .radiators_wrap .radiator_desc ul.additional_characteristics li p {
    font-size: 14px;
    line-height: 20px;
  }

  .thanks_wrap h1 {
    font-size: 36px;
  }

  .thanks_wrap p {
    font-size: 16px;
  }

  .thanks_wrap a {
    display: block;
  }

  .directory_selection h2:before {
    width: 300px;
    margin-left: -150px;
  }
}